import Filters from "../assests/Filters";
import "./Catalogo.css";
import ProdutosDiv from "../assests/ProdutosGrid";
import CardProduto from "../assests/CardProduto";
import { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Container, LoadingOverlay, Pagination } from "@mantine/core";
import { server } from "../config";
import { useSearchParams } from "react-router-dom";


function Catalogo() {
    
  const [produtos, setProdutos] = useState([]);
  const [marca, setMarca] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setPage] = useState(1);
  const [pagetotal, setPageTotal] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [prices, setPrices] = useState('')


  const getProdutos = async (sortby = "NOME", sort = "asc", minValue="0", maxValue="100000") => {

    
    let query = `produtos`;
    let sorting = `?sort=${sortby}:${sort}`;
    let limit = `&limit=15`
    let priceRange = `&priceRange=${minValue},${maxValue}`
    let marca = ``;
    let page = `&page=${activePage}`;
    setLoading(true);

   



    if (searchParams.get("MARCA")) {
      marca = `&MARCA=${searchParams.get("MARCA")}`;
      
    }
    if (searchParams.get("SEARCH")) {
      query = `produtos?SEARCH=${searchParams.get("SEARCH")}`;
      sorting = `&sort=${sortby}:${sort}`;
    }
    if (searchParams.get("CATEGORIA")) {
      query = `produtos?CATEGORIA=${searchParams.get("CATEGORIA")}`;
      sorting = `&sort=${sortby}:${sort}`;
      
      if (searchParams.get("TIPO")) {
        query = `produtos?CATEGORIA=${searchParams.get(
          "CATEGORIA"
        )}&TIPO=${searchParams.get("TIPO")}`;
      }
    }

    try {
      const res = await axios.get(server + query + sorting + limit+ marca + page + priceRange);
      
      setProdutos(res.data.produtos.rows);
      setMarca(res.data.marca);
      setPageTotal(res.data.totalPages);

      if(activePage> res.data.totalPages){
        setPage(1)
        
      }
      


      //preco maximo e minimo
      
      // const minPrice = parseFloat(res.data.minAndMaxPrices.minPrice);
      // const maxPrice = parseFloat(res.data.minAndMaxPrices.maxPrice);
      // setPrices([minPrice, maxPrice]);
      

      
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data);
      setLoading(false);
    }
   
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    getProdutos();
    
  }, [searchParams, activePage]);

  return (
    <div className="filtros">
      <Filters
        marcas={marca}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <h3 style={{ margin: "20px" }}>
        {produtos < 1 ? "Nenhum produto encontrado :(" : ""}
      </h3>

      <LoadingOverlay visible={loading} overlayBlur={1} />

      <ProdutosDiv getProdutos={getProdutos} title={"Produtos:"} filtros={true}>

       

        {produtos.map((produto) => {
          return (
            <CardProduto
              img={produto.imagens[0].PATH}
              titulo={produto.NOME}
              descricao={produto.DESCRICAO}
              preco={produto.PRECO.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              novo={produto.NOVO}
              disponivel={produto.DISPONIVEL}
              id={produto.id}
            />
          );
        })}
      </ProdutosDiv>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{margin: '20px'}}>
        <Pagination
          color="cyan"
          value={activePage}
          onChange={setPage}
          total={pagetotal}
         
        />
        </div>
      </Container>
    </div>
  );
}

export default Catalogo;
