import { useMemo, useLayoutEffect } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import "./AboutPage.css";

export default function AboutPage() {

function Map() {
  const center = useMemo(
    () => ({ lat: -19.92496414330416, lng: -43.94310416137158 }),
    []
  );

  return (
    <GoogleMap zoom={18} center={center} mapContainerClassName="map-container">
      <MarkerF position={center} />
    </GoogleMap>
  );
}

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD9KyTX-q-PHiM6s4pDMlRg08uQXuaQR7Q",
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="div-contato">
      <div className="div-content">
        <Map />
        <div className="info">
          <h1>CONTATO E INFORMAÇÕES:</h1>
          <p>
            <b>Endereço:</b> R. Curitiba, 1396 - Lourdes, Belo Horizonte - MG,
            30170-121
          </p>
          <p>
            <b>E-mail:</b> i9tecnologiabh@gmail.com
          </p>
          <p>
            <b>Telefone 1:</b> +55 (31) 99447-2332
          </p>
          <p>
            <b>Telefone 2:</b> +55 (31) 2526-2720
          </p>
        </div>
      </div>
    </div>
  );
}

