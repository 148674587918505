import { Card, Group, Image, Text, Button, Badge } from "@mantine/core";
import {images} from "../config";



function CardProduto({titulo,descricao,preco,img,id,novo,disponivel}){


  return (
   
        <Card 
          
        
        
        sx={{

          transition:'150ms',
          '&:hover': {
            backgroundColor: 'var(--lightergrey)',
          },
          '&:hover .detailbutton': {
            transition: '150ms',
            backgroundColor: 'var(--fourth-color)',
            color: '#ffffff'
          },
           }}   
          className='card-div' shadow="sm" padding="lg" radius="md" withBorder>
           
            <Card.Section>
                <Image 
                src={images+img}
                height={200}
                fit="contain"
                style={{ position: 'relative' }}
                />

                {disponivel?(novo&&(novo==="novo"?
                <Badge
                      color="green"
                      variant="light"
                      size="sm"
                      style={{
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                        zIndex: 1
                      }} // Add this style to the badge component
                    >
                      Novo
                    </Badge>:<Badge
                      color="orange"
                      variant="light"
                      size="sm"
                      style={{
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                        zIndex: 1
                      }} // Add this style to the badge component
                    >
                      Semi-novo
                    </Badge>)):(<Badge
                      color="red"
                      variant="filled"
                      size="sm"
                      style={{
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                        zIndex: 1
                      }} // Add this style to the badge component
                    >
                      Indisponível
                    </Badge>)}
                


                    
                
            </Card.Section>

            <Group position="apart" mt="md" mb="xs">
                <Text weight={500} lineClamp={1}>{titulo}</Text>
    
            </Group>
            

            <Text bt size="sm" color="dimmed" lineClamp={2}>
               {descricao}
            </Text>

            
            <Text mt="md" fz="xl" fw={700} sx={{ lineHeight: 1 }}>
              {disponivel ?  preco: 'Indisponível'}
            </Text>


            <Button styles={(theme) => ({ 
              root:{
                transition: '150ms',
                '&:hover': theme.fn.hover({
                  backgroundColor: theme.fn.darken('#1d9aad', 0.05),
                })
              }
          })}
            className="detailbutton" component="a" href={"/produto/"+id} variant="outline" color="blue" fullWidth mt="md" radius="xl"
           >
                Detalhes
            </Button>

            

    	</Card>
  );
}
export default CardProduto;