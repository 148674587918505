import { Outlet } from "react-router-dom";
import Footer from "../assests/Footer";
import Navbar from "../assests/Navbar";

import './Section.css'

const Layout =()=>{


    return (
      <>
        <Navbar />

        <section className="secaolayout">
          <Outlet />
        </section>
        <Footer />
      </>
    );

    
}

export default Layout;