import { Button,Badge, Flex} from '@mantine/core'
import { rem, Modal } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useDisclosure } from '@mantine/hooks';
import styles from './ProductPage.module.css'
import { IconBrandWhatsapp } from '@tabler/icons-react';


import { useParams } from "react-router-dom"
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { server, images } from '../config';
const cookies  = new Cookies();




const ProductPage = () =>{

    const [produto, setProduto] = useState([])
    const [contato, setContato] = useState([])
    const [opened, { open, close }] = useDisclosure(false);


    const { id } = useParams() 

    const token = cookies.get("parceiro")

    

    const getProduto = async ()=>{
        try{
            const res = await axios.get(server+`produtos/${id}`);
            setProduto(res.data)

        }catch(error){
            toast.error(error.response.data)
        }
    }

    const getContatos = async ()=>{


        try {

            const contatos = await axios.get(server+`contatos`)
            setContato(contatos.data)
            
        } catch (error) {
            
        }

    }




    


    useEffect(()=>{
        getProduto();
        getContatos();
    },[])

    






    return(
        <main className={styles.container}>
            <div className={styles.leftcolumn}>
            <Carousel className={styles.imgcarousel} loop withIndicators slideSize="100%" height={400} slideGap="md" styles={{
                indicator: {
                backgroundColor: '#00acee',
                width: rem(12),
                height: rem(4),
                transition: 'width 250ms ease',
                '&[data-active]': {
                    width: rem(40),
                },
                },
            }}>
             

                {produto.imagens && produto.imagens.map((imagem)=>{
                        return(
                            <Carousel.Slide>
                                <img src={images+imagem.PATH}></img>
                            </Carousel.Slide>
                        )
               })}


                
            </Carousel>
            </div>
            <div className={styles.rightcolumn}>

                
                    <div className={styles.productdescription} >

                        <span>{produto.CATEGORIA}</span>
                        <h1>{produto.NOME}</h1>
                        <p>{produto.DESCRICAO}
                        </p>
                    </div>

                    <div className={styles.divtags}>
                    <span>Tags:</span>

                        <div className={styles.tags} >
                        <Badge color="blue" size="md" variant="outline">{produto.MARCA}</Badge>
                        <Badge color="blue" size="md" variant="outline">{produto.TIPO}</Badge>

                    </div>
                    </div>

                    <Modal size='auto' centered opened={opened} onClose={close} title="Contato">
                      
                      <Flex
                           mih={50}
                           gap="md"
                           justify="center"
                           align="center"
                           direction="column"
                           wrap="wrap">

                            {contato&& contato.map((contato)=>{
                                return(
                                    <Button leftIcon={<IconBrandWhatsapp/>} fullWidth component="a" href={`https://wa.me/${contato.NUMERO}?text=Olá!%20tenho%20interesse%20em%20${produto.NOME}`} size="sm"  radius="xl" color={"cyan"}>{contato.NOME}</Button>
                                )
                            })}
                        
                      </Flex>
                    </Modal>


                    {produto.REVENDA&&token?<span style={{ display:'flex', marginBottom:'15px', color:'white', borderRadius:'20px', flexDirection:'column', padding:'10px', maxWidth:'300px', backgroundColor:'#007ea7'}}><label style={{fontSize: '15px'}}>Preço para revenda: </label>{produto.REVENDA.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>:""}

                <div className={styles.productprice}>
                    <span>{produto.DISPONIVEL?produto.PRECO&&produto.PRECO.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }): 'Indisponível'}</span>
                    
     
                    <Button size="sm" onClick={open}  radius="xl" color={"cyan"}>Tenho interesse!</Button>
                </div>
       


            </div>
        </main>
    )

}
export default ProductPage