import axios from "axios"

import { toast } from "react-toastify";



export const setAxiosInterceptors = (cookies)=>{


  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        cookies.remove('TOKEN');
        toast.error("Sua sessão expirou, faça login novamente");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

}


export const setAuthToken = token =>{
    if (token){
      axios.defaults.headers.common["Authorization"] =`Bearer ${token}`
    }else{
      delete axios.defaults.headers.common["Authorization"]
    }
}

