import './Table.css'
import { images } from '../config'
import { Checkbox } from '@mantine/core'
import { Link } from 'react-router-dom'


const Table = ({columns, data, handleDelete, handleDestaque}) =>{

    return(
        <div className='divtable'>
        <table>
            <thead>
                <tr>

                    {columns.map(({label,accessor})=>{
                        return(
                        <th key={accessor}>{label}</th>
                        )
                    })}
                    <th>Ações</th>
                    <th>Destaque</th>
                </tr>
            </thead>



            <tbody>    

            {data.map((data) => {

                return (
                
                <tr key={data.id}>
                    
                    {columns.map(({ accessor }) => {

                        const tData = data[accessor] ? data[accessor] : " ";
                        const tImage = tData[0] ? tData[0].PATH : " ";

                        return (accessor=='imagens'?
                        <td key={accessor}>
                            <img src={images+tImage}/>
                        </td>:
                        <td key={accessor}>{tData}</td>) ;

                    })}
                    <td>
                        <Link to={`/addProduto/${data.id}`}><button className='unstyled-button'><i className='fa fa-2x fa-edit'></i></button></Link>
                        <button onClick={()=>{handleDelete(data.id)}} className='unstyled-button'><i className='fa fa-2x fa-trash '></i></button>
                    </td>
                    <td>
                        <Checkbox color="cyan" label='Destacar' checked={data.DESTAQUE}
                        onChange={(event) => handleDestaque(event.currentTarget.checked, data.id)}> </Checkbox>
                    </td>
                </tr>
                );
            })}
                

                    
                
            </tbody>
        </table>

        </div>
    )
}

export default Table