import { ScrollArea } from "@mantine/core";
import "./CategoryGrid.css";
const CategoryGrid = (props) => {
  return (
    <>
      <ScrollArea h='130px' className="scrollarea">
        <div>
          <div className="div-category">{props.children}</div>
        </div>
      </ScrollArea>
    </>
  );
};

export default CategoryGrid;
