import { Button, TextInput, Container, Input, Modal, Image } from "@mantine/core"
import { Flex, Table, ActionIcon } from '@mantine/core'

import { Link } from "react-router-dom"
import ordenar from '../img/ordenar (1).png'
import Table2 from "../assests/Table"
import { toast } from "react-toastify";
import axios from "axios";
import { Pagination, LoadingOverlay, Popover } from '@mantine/core';
import './Admin.css';
import { useState, useEffect } from "react";
import { IconTrendingUp, IconTrendingDown } from '@tabler/icons-react'
import { useDebouncedState } from '@mantine/hooks';
import { IconUser, IconCirclePlus, IconTrash, IconDownload, IconPlayerPlay } from '@tabler/icons-react';
import Slider from "../assests/SlideShowMantine";
import { useFullscreen } from '@mantine/hooks';

import { server } from "../config";

const Admin = () => {



    const columns = [
        { label: "#", accessor: "id" },
        { label: 'Imagem', accessor: 'imagens' },
        { label: "Nome", accessor: "NOME" },
        { label: "Valor R$", accessor: "VALOR" },
    ]
    const [produtos, setProdutos] = useState([])
    const [produtosSlide, setProdutosSlide] = useState([])

    const [search, setSearch] = useDebouncedState('', 200);
    const [categorias, setCategorias] = useState([])
    const [activePage, setPage] = useState(1);
    const [pagetotal, setPageTotal] = useState(1);
    const [loading, setLoading] = useState(false);
    const [openAdm, setOpenAdm] = useState(false)


    const [openRelatorios, setOpenRelatorios] = useState(false)
    const { ref, toggle, fullscreen } = useFullscreen();

    const [contato, setContato] = useState([])

    const getProdutos = async (sortby = 'NOME', sort = 'asc') => {

        let query = `produtos?page=${activePage}`
        let sorting = `&sort=${sortby}:${sort}`;
        if (search !== '') {
            query = `produtos?SEARCH=${search}&page=${activePage}`
        }
        try {
            setLoading(true);
            const res = await axios.get(server + query + sorting);

            setProdutos(res.data.produtos.rows)
            setCategorias(res.data.categorias)
            setPageTotal(res.data.totalPages)
            setLoading(false);
            console.log(produtos)
        } catch (error) {
            toast.error(error.response.data)
            setLoading(false);
        }
    }




    const getContatos = async () => {


        try {

            const contatos = await axios.get(server + `contatos`)
            setContato(contatos.data)

        } catch (error) {

        }

    }
    const handleContatos = async (e) => {

        e.preventDefault()
        try {


            await axios.post(server + `contatos`, {
                nome: e.target.nome.value,
                numero: e.target.numero.value.replace(/[^0-9]/g, "")
            })

            getContatos()
            toast.success("Contato cadastrado com sucesso!")


        } catch (error) {
            toast.error("Erro ao enviar contato.")
        }
    }

    const handleDeleteContato = async (id) => {
        try {


            await axios.delete(server + `contatos/${id}`)

            getContatos()
            toast.success("Contato excluido com sucesso!")


        } catch (error) {
            toast.error("Erro ao excluir contato.")
        }
    }

    const rows = contato.map((contato) => (
        <tr key={contato.id}>
            <td>{contato.NOME}</td>
            <td>{contato.NUMERO}</td>
            <td><ActionIcon onClick={() => { handleDeleteContato(contato.id) }} color={'red'} variant={'outline'}><IconTrash /></ActionIcon></td>
        </tr>
    ));
    const handleDestaque = (boolean, id) => {

        setLoading(true);
        axios
            .put(server + `produtos/${id}/${boolean}`)
            .then(({ data }) => {

                getProdutos();
                setLoading(false);
            })
            .catch(({ data }) => {
                setLoading(false);
                toast.error(data)
            });


    }
    const handleDelete = (id) => {
        setLoading(true);
        axios
            .delete(server + `produtos/${id}`)
            .then(({ data }) => {

                toast.success(data);
                getProdutos();
                setLoading(false);
            })
            .catch(({ data }) => {
                setLoading(false);
                toast.error(data)
            });
    };

    const getProdutosSlide = async (sortby = 'NOME', sort = 'asc', minValue = "0", maxValue = "100000") => {

        let priceRange = `&priceRange=${minValue},${maxValue}`

        try {
            setLoading(true)
            const res = await axios.get(server + `produtos?limit=1000&sort=${sortby}:${sort}${priceRange}`);
            setProdutosSlide(res.data.produtos.rows)
            console.log(produtosSlide)
            console.log(res)


        } catch (error) {
            setLoading(false)
            toast.error(error.response.data)

        } finally {
            setLoading(false)
        }
    }

    const downloadRelatorio = async (categoria) => {
        setLoading(true)
        try {
            const response = await axios.get(server + 'relatorio/' + categoria, { responseType: 'blob' })
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${categoria}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            toast.error("Houve um erro no download do relatório")
        } finally {
            setLoading(false)
        }
    }

    const showSlides = async () => {
        // await getProdutosSlide();
        toggle();
    };

    useEffect(() => {
        getProdutos();
        // getProdutosSlide();
        getContatos();
    }, [search, activePage])

    useEffect(()=>{
        getProdutosSlide();
    },[])

    return (

        <>

            <LoadingOverlay visible={loading} overlayBlur={1} />
            <div className="div-adm">

                <Slider ref={ref} produtos={produtosSlide} show={fullscreen} />

                <div>   

                    <Button mr={'15px'} leftIcon={<IconUser size="1rem" />} size="sm" mb={10} color={'teal'} onClick={setOpenAdm} w={160} radius='xl'>Editar contatos</Button>
                    <Button mr={'15px'} leftIcon={<IconDownload size="1rem" />} size="sm" mb={20} color={'cyan'} onClick={setOpenRelatorios} w={160} radius='xl'>Baixar relatório</Button>
                    <Button leftIcon={<IconPlayerPlay size="1rem" />} size="sm" mb={20} color={'cyan'} onClick={showSlides} w={160} radius='xl'>Ver SlideShow</Button>

                </div>{}

                <div className="div-title">
                    <h1>
                        Produtos
                    </h1>
                    <TextInput
                        onChange={(event) => setSearch(event.currentTarget.value)} w={'50%'} placeholder="Pesquise por nome, descrição, categoria ou tipo..." icon={<i className="fa fa fa-search"></i>} />

                    <Link radius={'md'} to={"/addProduto"}>
                        <Button radius="md" variant="outline" >+ Adicionar</Button></Link>
                </div>
                <div>


                    <Popover width={320} position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <Button variant="outline" color="blue" mt="xs" radius="md">
                                <img className="img-order" src={ordenar} />
                                Ordenar
                            </Button>
                        </Popover.Target>


                        <Popover.Dropdown>
                            <Button
                                onClick={() => {
                                    getProdutos("createdAt", "desc");
                                }}
                                variant="subtle"
                                fullWidth
                                rightIcon={<IconTrendingUp size="1.5rem" />}
                            >
                                Mais recente
                            </Button>


                            <hr style={{ margin: "5px" }} />
                            <Button
                                onClick={() => {
                                    getProdutos("createdAt", "asc");
                                }}
                                variant="subtle"
                                fullWidth
                                rightIcon={<IconTrendingDown size="1.5rem" />}
                            >
                                Mais antigo
                            </Button>



                        </Popover.Dropdown>
                    </Popover>
                </div>
                <Table2
                    handleDelete={handleDelete}
                    handleDestaque={handleDestaque}
                    columns={columns}
                    data={produtos}
                />
            </div>


            <Modal size='auto' centered opened={openAdm} onClose={() => { setOpenAdm(false) }} title="Editar vendedores">

                <form onSubmit={handleContatos}>


                    <Flex gap="md">
                        <Input required name={'nome'} placeholder="Nome do Contato" radius="md">
                        </Input>

                        <Input required name={'numero'} placeholder="Numero de Telefone" radius="md">
                        </Input>

                        <Button color={'green'} type={'submit'}><IconCirclePlus size="1.3rem" /></Button>

                    </Flex>
                </form>
                <Table highlightOnHover>



                    <thead>
                        <tr>
                            <th style={{ color: 'white' }}>Nome</th>
                            <th style={{ color: 'white' }}>Numero</th>
                            <th style={{ color: 'white' }}>Excluir</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>



                </Table>


            </Modal>
            <Modal size='auto' centered opened={openRelatorios} onClose={() => { setOpenRelatorios(false) }} title="Emitir relatório:">


                <Flex
                    mih={50}
                    gap="md"
                    justify="center"
                    align="center"
                    direction="column"
                    wrap="wrap">

                    {categorias && categorias.map((categoria) => {
                        return (
                            <Button fullWidth onClick={() => downloadRelatorio(categoria)} size="sm" radius="xl" color={"cyan"}>{categoria}</Button>
                        )
                    })}

                </Flex>

            </Modal>





            <Container style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }} >
                <Pagination color="cyan" value={activePage} onChange={setPage} total={pagetotal} />
            </Container>
        </>




    )

}

export default Admin