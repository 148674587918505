import { Link } from "react-router-dom";
import { Image } from "@mantine/core";
import { MDBRipple } from "mdb-react-ui-kit";
import './FilterButton.css'

function FilterButton({icone, text, categoria,tipo,apple}) {
    
  return (
    
    <>



    {!apple?
    <Link className="link-filtros" to={"?CATEGORIA=" +categoria+(tipo?'&TIPO='+tipo:'')}>
            <MDBRipple
              className="ripple hover-shadow hover-overlay"
              rippleTag="div"
              rippleColor="light"
            >
              <button  className="botao">
                <Image width={30} height={30} src={icone} caption={text} />
              </button>
              <div 
              
                className="mask"
                style={{
                  background:
                    "linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)",
                }}
              ></div>
            </MDBRipple>
          </Link>:

          <Link className="link-filtros" to={"?MARCA=Apple"}>
          <MDBRipple
            className="ripple hover-shadow hover-overlay"
            rippleTag="div"
            rippleColor="light"
          >
            <button className="botao">
            <Image width={30} height={30} src={icone} caption={text} />
            </button>
            <div
              
              className="mask"
              style={{
                background:
                  "linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)",
              }}
            ></div>
          </MDBRipple>
          </Link>
}


      
    </>
  );
}

export default FilterButton;



