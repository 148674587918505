import './Slider.css'
import { forwardRef, useState } from 'react';
import background from '../img/SlideBackground.png'
import { Image } from '@mantine/core';
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { images } from "../config";
import { Carousel } from '@mantine/carousel';
const SlideShow = forwardRef(function SlideShow({ produtos, show }, ref) {

  const rightColumn = {
    width: '40%',
    marginTop: '60px',
    position: 'relative',
    float: 'right',
    textAlign: 'left',
    paddingRight: '50px',

  }
  const leftColumn = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    marginTop: '60px',
    textAlign: 'center',
    position: 'relative',
    height: '100vh'
  }

  const autoplay = useRef(Autoplay({ delay: 6000 }));



  return (
    <div style={{ margin: 'auto', }}>
      <div

        ref={ref}
        className="div-carousel"
        style={{
          marginTop: "40px",
          marginBottom: "20px",
          overflow: "hidden",

        }}
      >


        {show &&

          <Carousel
            withIndicators
            plugins={[autoplay.current]}
          >



            {produtos && produtos.map((produto, key) => {
              return (
                <Carousel.Slide
                  className="w-100 d-block"
                  key={key + 1}
                  style={{ background: `url(${background}) no-repeat`, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
                >

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '100%' }}>
                    <div style={leftColumn}>


                      <Image
                        radius="lg"
                        height={'60vh'}
                        fit="contain"
                        src={images + produto.imagens[0].PATH}
                      />

                    </div>

                    <div style={rightColumn}>

                      <h1
                        style={{
                          color: '#0D3857', fontWeight: 'bolder', fontSize: '60px', letterSpacing: '-3px',
                          marginBottom: '30px', fontFamily: 'Poppins',
                        }}>{produto.NOME}
                      </h1>
                      <p style={{
                          fontSize: '16px',
                          fontFamily: 'Poppins',
                          color: '#202020',
                          fontWeight: 'lighter',
                          whiteSpace: 'pre-wrap',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 20, // Limiting to 10 lines
                      }}>
                        {produto.DESCRICAO}
                      </p>
                      <span style={{ fontSize: '50px', fontWeight: 'bolder', fontFamily: 'Poppins', color: '#026AB3' }}
                      >{produto.PRECO && produto.PRECO.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                      </span>

                    </div>

                  </div>

                </Carousel.Slide>


              )
            })}




          </Carousel>














        }


      </div>

    </div>
  );
});

export default SlideShow;