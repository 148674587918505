import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NewProduct from "./pages/NewProduct";
import "./App.css";
import Layout from "./layout/Layout";
import "font-awesome/css/font-awesome.min.css";
import Admin from "./pages/Admin";
import ProductPage from "./pages/ProductPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Catalogo from "./pages/Catalogo";
import AboutPage from "./pages/AboutPage";
import RotasProtegidas from "./assests/RotasProtegidas";
import Login from "./pages/Login";
import Cookies from "universal-cookie";
import { setAuthToken, setAxiosInterceptors } from "./assests/SetAuthToken";
const cookies = new Cookies();

function App() {
  
  const token = cookies.get("TOKEN");
  if (token) {
    
    setAuthToken(token);
    setAxiosInterceptors(cookies)
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<Login />} />

            <Route path="/" element={<RotasProtegidas /> }>

            

            
              <Route path="/admin" element={<Admin />} />
              <Route path="/addProduto" element={<NewProduct />} />
              <Route
                path="/addProduto/:id"
                
                element={<NewProduct detalhes={true} />}
              />
            </Route>
            {/* rotas liberadas */}
            <Route index element={<Home />} />
            <Route path="/produto/:id" element={<ProductPage />} />
            <Route path="/catalogo" element={<Catalogo />} />
            <Route path="/contato" element={<AboutPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={3000} position={toast.POSITION.TOP_CENTER} />
    </>
  );
}

export default App;
