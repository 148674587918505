import { Button, TextInput, Textarea, LoadingOverlay, FileButton, Group, Select, Radio, Switch } from '@mantine/core'
import { Carousel } from '@mantine/carousel';
import { useEffect, useState, useRef } from 'react';
import { IconCheck, IconX } from '@tabler/icons-react';
import axios from "axios";
import { toast } from "react-toastify";
import upload from '../img/upload.png'
import styles from './NewPage.module.css'
import { server, images } from '../config';

import { useNavigate, useParams } from "react-router-dom";
import { MDBInput } from 'mdb-react-ui-kit';





const NewProduct = (props) => {

    const navigate = useNavigate();
    const { id } = useParams()
    const [imagens, setImagens] = useState([])
    const [produto, setProduto] = useState([])
    const [imageURLs, setImageURLs] = useState([])
    const [selectValue, setSelectValue] = useState(null)
    const [novo, setNovo] = useState(null)
    const [value1, setValue1] = useState(0);
    const [value2, setValue2] = useState(0);
    const [value3, setValue3] = useState(0);
    const [checked, setChecked] = useState(true);


    const [loading, setLoading] = useState(false);
    //const [editar, setEditar] = useState(props.detalhes? true : false)


    const form = useRef()



    const getProduto = async () => {
        const produtoInfo = form.current
        
        try {
            setLoading(true);

            const res = await axios.get(server + `produtos/${id}`);
            const produtodados = res.data
            setProduto(res.data)
            produtoInfo.nome.value = produtodados.NOME
            setNovo(produtodados.NOVO)
            produtoInfo.descricao.value = produtodados.DESCRICAO
            setSelectValue(produtodados.CATEGORIA)
            produtoInfo.marca.value = produtodados.MARCA
            produtoInfo.tipo.value = produtodados.TIPO
            setValue1(produtodados.VALOR)
            setValue2(produtodados.PRECO)
            setValue3(produtodados.REVENDA)
            setChecked(produtodados.DISPONIVEL)

            setLoading(false);
        } catch (error) {
            toast.error(error.response.data)
            setLoading(false);
        }
    }



    const handlePost = async (e) => {
        e.preventDefault()
        setLoading(true);


        if (!form.current.nome || !form.current.nome.value || !form.current.descricao.value || !form.current.categoria.value
            || !form.current.marca.value || !form.current.tipo.value || !form.current.valor.value || !form.current.preco.value || !form.current.revenda.value) {
            setLoading(false);
            return toast.warning("Preencha todos os dados corretamente")
        } else {
            if (imagens.length > 4) {
                setLoading(false);
                return toast.warning("Selecione no máximo 4 imagens!")
            }
        }



        const dataForm = new FormData(form.current);

        const inputTipo = dataForm.get('tipo')
        dataForm.set('tipo', inputTipo.toUpperCase())
        dataForm.append('disponivel',checked)

        imagens.forEach((file) => {
            dataForm.append(`files`, file);//file-${i}
        });

        if (!props.detalhes) {


            await axios.post(server + `produtos`, dataForm, {



                headers: {
                    "Content-Type": "multipart/form-data"
                }

            })
                .then(({ data }) => {
                    console.log(data)

                    toast.success("Produto cadastrado com sucesso!")
                    setLoading(false);

                    navigate('/admin')
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false);
                    toast.warning(error.response.data)
                })

        } else {

            await axios.put(server + `produtos/${id}`, dataForm, {

                headers: {
                    "Content-Type": "multipart/form-data"
                }

            })
                .then(({ data }) => {
                    console.log(data)
                    setLoading(false);
                    toast.success("Produto atualizado com sucesso!")
                    navigate('/admin')
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false);
                    toast.warning(error.response.data)
                })

        }




    }


    useEffect(() => {
        console.log(props.detalhes)
        if (props.detalhes) {
            getProduto();
        }

        if (imagens.length < 1) return;
        const newImageUrls = [];
        imagens.forEach(image => newImageUrls.push(URL.createObjectURL(image)));
        setImageURLs(newImageUrls)
    }, [imagens])

    const clearFile = () => {
        setImagens([])
    }


    return (
        <form ref={form} onSubmit={handlePost} className={styles.container}>
            <LoadingOverlay visible={loading} overlayBlur={1} />
            <div className={styles.leftcolumn}>
                <div className={styles.leftcolumntext}><span>*opte por imagens com o fundo branco</span></div>



                <Carousel className={styles.imgcarousel} mx="auto" withIndicators slideSize="100%" height={400}
                    styles={{
                        control: {
                            '&[data-inactive]': {
                                opacity: 0,
                                cursor: 'default',
                            },
                        },
                    }}>
                    {props.detalhes && imagens.length < 1 ? produto.imagens && produto.imagens.map((imagem) => {
                        return (
                            <Carousel.Slide>
                                <img src={images + imagem.PATH}></img>
                            </Carousel.Slide>
                        )
                    }) :


                        imagens.length < 1 ? <img className={styles.uploadimg} src={upload} /> :
                            imageURLs.map(imageSrc =>
                                <Carousel.Slide>
                                    <img src={imageSrc} />
                                </Carousel.Slide>)}
                </Carousel>


                <Group position="center" className={styles.leftcolumnbuttons}>
                    <FileButton mt={10} size="sm" radius="xl" color={"gray"} onChange={setImagens} accept="image/png,image/jpeg" multiple>
                        {(props) => <Button {...props}>Selecionar imagens</Button>}
                    </FileButton>
                    <Button disabled={imagens.length < 1} mt={10} size="sm" radius="xl" color="red" onClick={clearFile}>
                        Limpar
                    </Button>
                </Group>


            </div>
            <div className={styles.rightcolumn}>


                <div className={styles.productdescription} >
                    <TextInput name='nome' variant={'filled'} label="Titulo" placeholder="Titulo do produto" />

                    <Select value={selectValue} onChange={setSelectValue} variant={'filled'} name='categoria' label="Categoria" placeholder="..."
                        data={[{ value: 'computadores', label: 'Computadores' },
                        { value: 'perifericos', label: 'Periféricos' },
                        { value: 'celulares', label: 'Celulares' },
                        { value: 'pecas', label: 'Peças' },
                        { value: 'ipad', label: 'IPads' },
                        { value: 'monitores', label: 'Monitores' },
                        { value: 'gabinetes', label: 'Gabinetes' },
                        { value: 'outros', label: 'Outros' },]} />
                    <Textarea name='descricao' autosize minRows={2} maxRows={10} variant={'filled'} label="Descrição" placeholder="Descrição do produto" />

                    <TextInput name='marca' variant={'filled'} label="Marca" placeholder="Ex: APPLE, ASUS..." />
                    <TextInput name='tipo' variant={'filled'} label="Tipo" placeholder="Ex: IPHONE, NOTEBOOK, MONITOR... " />

                </div>






                <div class={styles.productprice}>
                    <div className={styles.values}>
                    <Switch
                            w={'100%'}
                            
                            checked={checked}
                            onChange={(event) => setChecked(event.currentTarget.checked)}
                            color="teal"
                            size="md"
                            label={checked?("Produto disponível"):("Produto indisponível")}
                            thumbIcon={
                                checked ? (
                                    <IconCheck size="0.8rem" color="teal" stroke={3} />
                                ) : (
                                    <IconX size="0.8rem" color="red" stroke={3} />
                                )
                            }
                        />

                        <Radio.Group
                            name="novo"
                            label="Produto novo ou seminovo?"
                            w={'100%'}
                            value={novo}
                            onChange={setNovo}
                        >
                            <Group mb={20} mt="xs">
                                <Radio value="novo" label="Novo" />
                                <Radio value="seminovo" label="Semi-novo" />
                            </Group>


                        </Radio.Group>
                        

                        <MDBInput onChange={(e) => setValue1(e.target.value)} value={value1} label='Valor (comprado)' id='typeNumber' name='valor' type='number' />

                        <MDBInput onChange={(e) => setValue2(e.target.value)} value={value2} label='Preço (público)' id='typeNumber' name='preco' type='number' />
                        <MDBInput onChange={(e) => setValue3(e.target.value)} value={value3} label='Preço de revenda' id='typeNumber' name='revenda' type='number' />



                    </div>

                    <Button type='submit' className={styles.rightcolumnbuttons} mt={10} size="sm" radius="xl" color={"cyan"} loading={loading}>Cadastrar Produto</Button>
                </div>



            </div>
        </form>
    )

}
export default NewProduct