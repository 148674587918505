import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import logofooter from "../img/logo2.png";
import Cookies from "universal-cookie";


const cookies = new Cookies();

function Footer() {
  const token = cookies.get("TOKEN");

  return (
    <div style={{ boxShadow: "0 -2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
      <MDBFooter
        className="text-center text-muted"
        style={{ backgroundColor: "" }}
      >
        <MDBContainer fluid className="p-4 pb-0 border-bottom ">
          <section className="mb-4">
            <MDBBtn
              floating
              className="m-1 me-2 ms-2"
              style={{ backgroundColor: "#3b5998", borderRadius: "100px" }}
              role="button"
              href="https://www.facebook.com/i9tecnologiabh/?locale=pt_BR"
              target="_blank"
            >
              <MDBIcon fab icon="facebook-f" />
            </MDBBtn>

            <MDBBtn
              floating
              className="m-1 me-2 ms-2 hover-overlay"
              style={{ backgroundColor: "#dd4b39", borderRadius: "100px" }}
              href="https://www.google.com/search?q=i9+tecnologia"
              role="button"
              target="_blank"
            >
              <MDBIcon fab icon="google" />
            </MDBBtn>
            <MDBBtn
              floating
              className="m-1 me-2 ms-2 hover-overlay"
              style={{ backgroundColor: "#ac2bac", borderRadius: "100px" }}
              href="https://www.instagram.com/i9tecnologiabh/"
              role="button"
              target="_blank"
            >
              <MDBIcon fab icon="instagram" />
            </MDBBtn>

            <MDBBtn
              floating
              className="m-1 me-2 ms-2"
              style={{ backgroundColor: "#0082ca", borderRadius: "100px" }}
              href="https://www.linkedin.com/company/i9tecnologiabh/about/"
              role="button"
              target="_blank"
            >
              <MDBIcon fab icon="linkedin-in" />
            </MDBBtn>
          </section>
        </MDBContainer>
        <section className="">
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <MDBIcon color="secondary" icon="gem" className="me-3" />
                  i9 Tecnologia BH
                </h6>
                <img
                  src={logofooter}
                  alt="logofooter"
                  style={{ width: "200px" }}
                ></img>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Páginas</h6>
                <p>
                  <Link to="/" className="text-reset">
                    Home
                  </Link>
                </p>
                <p>
                  <Link to="/catalogo" className="text-reset">
                    Produtos
                  </Link>
                </p>
                <p>
                  <Link to="/contato" className="text-reset">
                    Contato
                  </Link>
                </p>
                <p>
                  {token ? (
                    <Link to="/admin" className="text-reset">
                      Administrador
                    </Link>
                  ) : (
                    <Link to="/login" className="text-reset">
                      Login
                    </Link>
                  )}
                </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Produtos</h6>
                <p>
                  <Link
                    to={"catalogo?CATEGORIA=computadores"}
                    className="text-reset"
                  >
                    Computadores
                  </Link>
                </p>
                <p>
                  <Link
                    to={"catalogo?CATEGORIA=perifericos"}
                    className="text-reset"
                  >
                    Periféricos
                  </Link>
                </p>
                <p>
                  <Link
                    to={"catalogo?CATEGORIA=celulares"}
                    className="text-reset"
                  >
                    Celulares
                  </Link>
                </p>
                <p>
                  <Link
                    to={"catalogo?CATEGORIA=pecas"}
                    className="text-reset"
                    style={{}}
                  >
                    Peças
                  </Link>
                </p>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contato</h6>
                <p style={{ marginBottom: "2px" }}>
                  <MDBIcon color="secondary" icon="home" className="me-2" />
                  R. Curitiba, 1396 - Lourdes, Belo Horizonte
                </p>
                <p style={{ marginBottom: "2px" }}>
                  <MDBIcon color="secondary" icon="envelope" className="me-3" />
                  i9tecnologiabh@gmail.com
                </p>
                <p style={{ marginBottom: "3px" }}>
                  <MDBIcon color="secondary" icon="phone" className="me-3" />{" "}
                  +55 (31) 99447-2332
                </p>
                <p>
                  <MDBIcon color="secondary" icon="phone" className="me-3" />{" "}
                  +55 (31) 2526-2720
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
    </div>
  );
}

export default Footer;
