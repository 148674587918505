import "./Home.css"
import axios from 'axios';
import { useState, useEffect, useLayoutEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import pc_icon from '../img/computer.png'
import key_icon from '../img/keyboard (2).png'
import tool_icon from '../img/hammer.png'
import gpu_icon from '../img/gpu.png'
import smartphone_icon from '../img/smartphone.png'
import '../assests/Card.css'
import CardProduto from '../assests/CardProduto';
import ProdutosDiv from '../assests/ProdutosGrid';
import Category from '../assests/Category';
import CategoryGrid from '../assests/CategoryGrid';
import { server } from '../config';
import { Link } from 'react-router-dom';
import Slider from "../assests/Slider";

/*
<Carousel
  radius="md"
  slideSize="100%"
  height={500}
  slideGap="sm"
  loop
  withIndicators
  className="carouselbanner"
>
  <Carousel.Slide className="imagembanner banner1"></Carousel.Slide>
  <Carousel.Slide className="imagembanner banner2">
    <a href={`https://wa.me/553197892322?text=${textAssistencia}`}>
      <button style={{ marginTop: "100px" }}>Assistencia</button>
    </a>
  </Carousel.Slide>
  <Carousel.Slide className="imagembanner banner3">
    <a href={`https://wa.me/553197892322?text=${textCustom}`}>
      <button style={{ marginTop: "100px" }}>Montar um PC!</button>
    </a>
  </Carousel.Slide>
</Carousel>;
*/

function Home() {

  const textAssistencia = "Olá eu gostaria de solicitar uma assistência técnica!"
  


    const [produtos, setProdutos] = useState([])


    const getProdutos = async (sortby='NOME',sort='asc', minValue="0", maxValue="100000") => {

      let priceRange = `&priceRange=${minValue},${maxValue}`
        
        try {
            const res = await axios.get(server + `produtos?limit=10&DESTAQUE=true&sort=${sortby}:${sort}${priceRange}`);
            setProdutos(res.data.produtos.rows)
            

        } catch (error) {
            toast.error(error.response.data)
        }
    }

    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    });

    useEffect(() => {
        getProdutos();
    }, [])


    return (
      <div>
        <Slider />
        <CategoryGrid>
          <Category categoria={"computadores"} icon={pc_icon} text="PC's" />
          <Category
            categoria={"perifericos"}
            icon={key_icon}
            text="Periféricos"
          />
          <Category
            categoria={"celulares"}
            icon={smartphone_icon}
            text="Celulares"
          />
          <Category categoria={"pecas"} icon={gpu_icon} text="Peças" />

          <Link to={`https://wa.me/553197892322?text=${textAssistencia}`}>
            <button className="button-category">
              <img src={tool_icon} alt="categorias" />
              <p>Assistência</p>
            </button>
          </Link>
        </CategoryGrid>

        <div className='backgroundProdutos'
          style={{
            marginTop: "40px",
            paddingTop: "40px",
            paddingBottom: "30px",
          }}
        >
          <ProdutosDiv getProdutos={getProdutos} title={"Destaques:"}>
            {produtos.map((produto) => {
              return (
                <CardProduto
                  img={produto.imagens[0].PATH}
                  titulo={produto.NOME}
                  descricao={produto.DESCRICAO}
                  preco={produto.PRECO.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  id={produto.id}
                  novo={produto.NOVO}
                  disponivel={produto.DISPONIVEL}
                />
              );
            })}
          </ProdutosDiv>
        </div>
      </div>
    );
}

export default Home;