import "./Filters.css";
import FilterButton from "./FilterButton";
import { Menu, ActionIcon } from "@mantine/core";
import {
  IconSettings,
  IconTrash
} from "@tabler/icons-react";
import computador from "../img/computador.png";
import teclado from "../img/teclado.png";
import laptop from "../img/laptop-icon.png";
import monitor from "../img/monitor-icon.png";
import mouse from "../img/mouse-icon.png";
import iphone from "../img/iphone.png";
import tablet from "../img/tablet.png"
import pecas from "../img/pecas.png";
import apple from "../img/logo-apple.svg"
import gabinete from "../img/computer-tower.png"

function Filters({ marcas, setSearchParams }) {

  const handleMarca = (marca) => {
    setSearchParams(searchParams => {
      searchParams.set("MARCA", marca.trim());
      return searchParams;
    });
  }
  return (

    <div className="div-filtros d-flex align-items-center">
      <Menu shadow="md" width={200}>
        <div className="settings flex-xl-grow-1">
          <Menu.Target className="" style={{}}>
            <ActionIcon
              color="dark"
              size="xl"
              radius="xl"
              variant="transparent"
            >
              <IconSettings size="2rem" />
            </ActionIcon>
          </Menu.Target>
        </div>
        <Menu.Dropdown>
          <Menu.Label>Marcas</Menu.Label>

          {marcas.map((marca) => {
            return (
              <Menu.Item onClick={() => handleMarca(marca.MARCA)}>
                {marca.MARCA}
              </Menu.Item>
            );
          })}

          <Menu.Divider />

          <Menu.Item

            component="a"
            href={"/catalogo"}
            color="red"
            icon={<IconTrash size={14} />}
          >
            Limpar filtros
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <FilterButton
        className="flex-shrink-0"
        icone={computador}
        text="PC's"
        categoria={"computadores"}
      />

      <FilterButton
        className="flex-shrink-1"
        icone={apple}
        text="Apple"
        apple={true}
      />
      <FilterButton

        className="flex-shrink-1"
        icone={laptop}
        text="Notebooks"
        categoria={"computadores"}
        tipo={"NOTEBOOK"}
      />
      <FilterButton

        className="flex-shrink-1"
        icone={tablet}
        text="IPad's"
        categoria={"ipad"}
      />
      <FilterButton

        className="flex-shrink-1"
        icone={monitor}
        text="Monitores"
        categoria={"monitores"}
      />
      <FilterButton

        className=""
        icone={gabinete}
        text="Gabinetes"
        categoria={"gabinetes"}
      />
      <FilterButton

        className=""
        icone={teclado}
        text="Teclados"
        categoria={"perifericos"}
        tipo={"TECLADO"}
      />
      <FilterButton

        className=""
        icone={mouse}
        text="Mouses"
        categoria={"perifericos"}
        tipo={"MOUSE"}
      />
      <FilterButton

        className=""
        icone={iphone}
        text="Celulares"
        categoria={"celulares"}
      />
      <FilterButton

        className=""
        icone={pecas}
        text="Peças"
        categoria={"pecas"}
      />
      <div className="flex-xl-grow-1"></div>
    </div>
  );
}

export default Filters;
