import './ProdutosGrid.css'
import { IconTrendingUp, IconTrendingDown } from '@tabler/icons-react'
import ordenar from '../img/ordenar (1).png'
import { useRef } from 'react'
import { Button, Popover, Text } from '@mantine/core'
import Masked from './Masked'

const ProdutosDiv = ({ title, children, getProdutos, filtros }) => {



  
  const form = useRef()



  const handleFiltro = (e) => {
    e.preventDefault()

    const value1 = form.current.inputInicial.value?parseFloat(form.current.inputInicial.value.replace(/[^0-9,]/g, '').replace(',', '.')): 0


    const value2 = form.current.inputFinal.value?parseFloat(form.current.inputFinal.value.replace(/[^0-9,]/g, '').replace(',', '.')): 100000


    
     getProdutos("PRECO", "asc",value1,value2)


  }








  return (
    <div className="display-produtos">
      <div className="head-products">
        <h1>{title}</h1>




        {filtros && <Popover width={320} position="bottom" withArrow shadow="md">
          <Popover.Target>
            <Button variant="outline" color="blue" mt="xs" radius="md">
              <img className="img-order" src={ordenar} />
              Ordenar
            </Button>
          </Popover.Target>


          <Popover.Dropdown>
            <Button
                onClick={() => {
                  getProdutos("PRECO", "desc");
                }}
                variant="subtle"
                fullWidth
                rightIcon={<IconTrendingUp size="1.5rem" />}
              >
                Maior preço
              </Button>


            <hr style={{ margin: "5px" }} />
            <Button
                onClick={() => {
                  getProdutos("PRECO", "asc");
                }}
                variant="subtle"
                fullWidth
                rightIcon={<IconTrendingDown size="1.5rem" />}
              >
                Menor preço
              </Button>


            <hr style={{ margin: "5px" }} />


            <form ref={form} onSubmit={handleFiltro}>

              <Text mb={15} c="blue">Faixa de Preço:</Text>

              
              <Masked input_name='inputInicial'
                label='preço inicial'>

              </Masked>


              <Text >até</Text>
              <Masked
              input_name='inputFinal'
                label='preço final'

              >

              </Masked>






              <Button type='submit' mt={15} onClick={handleFiltro} size='sm' radius={'xl'} fullWidth>
                Filtrar Preço
              </Button>

              

            </form>

          </Popover.Dropdown>
        </Popover>}


      </div>

      <div className="div-products">{children}</div>
    </div>
  );
}

export default ProdutosDiv 