import React, { useState } from "react";
import { TextInput, Button, Text , Box, Image} from "@mantine/core";
import { setAuthToken } from "../assests/SetAuthToken";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Login.css"
import usericon from "../img/usericon.png"
import { toast } from 'react-toastify';
import { server } from "../config";

const cookies  = new Cookies();

const LoginPage = () => {
  const [usuario, setUsername] = useState("");
  const [senha, setPassword] = useState("");
  const [error, setError] = useState("");

  const config = {
    method: "post",
    url: server+"sessions",
    data: {
      usuario,
      senha,
    },
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Perform validation, for example checking if username and password are not empty
    if (usuario === "" || senha === "") {
      setError("Por favor preencha todos os campos.");
    } else {
        axios(config).then((res)=>{
            const token = res.data.token
            const admin = res.data.user.ADMIN

           

      
    
            //setando cookie em todos os caminhos
            if(admin){

              cookies.set("TOKEN", token, {
                path: "/"
                
              })
              setAuthToken(token)
              window.location.href = "/admin"
            }else{
                cookies.set("parceiro", true, {
                  path: "/"
              })
              window.location.href = "/"
              

            }
    
            
            
    
            
            console.log(res)
        })
        .catch((error)=>{
          toast.error(error.response.data.error)
        })
      setError("");
    }
  };

  return (
    <div className="divbackground">
      <Box
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
          textAlign: "center",
          padding: theme.spacing.xl,
          borderRadius: theme.radius.md,
          cursor: "pointer",
          height: 500,
          width: 400,
          marginTop: 30,
          marginLeft: "auto",
          marginRight: "auto",

          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[5]
                : theme.colors.gray[1],
          },
        })}
      >
        <Image
          width={80}
          mx="auto"
          src={usericon}
          sx={(theme) => ({ marginTop: 40, marginBottom: 25 })}
        />
        <h1 style={{fontSize:'30px'}}>Login Admin</h1>
        <form onSubmit={handleFormSubmit}>
          <TextInput
            value={usuario}
            onChange={handleUsernameChange}
            placeholder="Username"
            required
            style={{
              marginBottom: "1rem",
              marginTop:"40px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          />
          <TextInput
            type="password"
            value={senha}
            onChange={handlePasswordChange}
            placeholder="Password"
            required
            style={{
              marginBottom: "2rem",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          />
          <Button
            type="submit"
            style={{
              width:"90%",
              borderRadius:"5px",
            }}
          >
            Login
          </Button>
          {error && (
            <Text
              align="center"
              color="red"
              size="sm"
              style={{ marginTop: "1rem" }}
            >
              {error}
            </Text>
          )}
        </form>
      </Box>
    </div>
  );
};

export default LoginPage;
