import { IMaskInput } from 'react-imask';
import './Masked.css'

const Masked = ({label='name', input_name='', input_width=225, label_width=75})=> {

    return (

        <div className={'masked-input-div'} style={{width : input_width}}>
            <div style={{width : label_width}} className={'border-hidder'}></div>
            <label>{label}</label>
            <IMaskInput name={input_name} mask="R$ num"
                blocks={{
                num: {
                    mask: Number,
                    thousandsSeparator: ".",
                    radix: ",",
                    scale: 2,
                },
                }}
                
                radix=","
                thousandsSeparator="."
                placeholder="R$ 0,00"/>
        </div>

    )
}

export default Masked
