import React, { useState } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBBtn,
  MDBNavbarNav,
  MDBIcon,
  MDBRow,
  MDBCol
} from "mdb-react-ui-kit";
import "./Navbar.css";
import logo from "../img/logoteste.png"
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { IconSearch } from "@tabler/icons-react";
import { TextInput } from "@mantine/core";


export default function Navbar() {


  const [showNavNoTogglerThird, setShowNavNoTogglerThird] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
      navigate("/catalogo?SEARCH=" + e.target.busca.value);
      //posso passar A. parametros, B. query, C. props, DECIDIR DEPOIs
    } catch (error) {
      toast.error(error.response.data);
    }
  };



  return (
    <>
      <MDBNavbar
        expand="lg"
        light
        bgColor="light"
        style={{ boxShadow: "0 4px 10px 2px rgba(0, 0, 0, 0.1)" }}
        fixed="top"
        className=""
      >
        <div
          className="logo-background"
          style={{
            position: "absolute",
            width: "70px",
            height: "84px",
            backgroundColor: "#026ab3",
          }}
        >
          <Link to="/">
            <img
              className="logo"
              src={logo}
              style={{ width: "89px", marginTop: "-5px" }}
            />
          </Link>
        </div>
        <MDBContainer fluid styles={{ height: "100px" }}>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavNoTogglerThird(!showNavNoTogglerThird)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBNavbarBrand href="/" className="h-auto">
            <img
              src={logo}
              alt=""
              style={{ width: "50px", backgroundColor: "" }}
            />
          </MDBNavbarBrand>

          <MDBCollapse
            className="text-center ps-2 pe-2"
            navbar
            show={showNavNoTogglerThird}
          >
            <MDBRow start>
              <MDBNavbarNav className=" mb-2 mb-lg-0 ms-0 pe-0" styles={{}}>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    className="links firstlink"
                    active
                    aria-current="page"
                    href="/"
                    style={{}}
                  >
                    HOME
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    className="links"
                    active
                    aria-current
                    href="/catalogo"
                    style={{}}
                  >
                    PRODUTOS
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    className="links"
                    active
                    aria-current
                    href="/contato"
                    tabIndex={-1}
                    aria-disabled="true"
                    style={{}}
                  >
                    CONTATO
                  </MDBNavbarLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            </MDBRow>

            <MDBRow className="justify-content-start w-100 ms-0" styles={{}}>
              <form
                className="d-flex flex-lg-row flex-column"
                onSubmit={handleSearch}
                style={{}}
              >
                <MDBCol
                  className="mb-2"
                  sm="6"
                  offsetSm={3}
                  lg="8"
                  xxl="4"
                  offsetLg={2}
                  style={{ marginTop: "15px" }}
                >
                  <section className="animation-focus">
                    <div className="input-wrapper" style={{ width: "" }}>
                      <TextInput
                        className="searchbar form-control"
                        placeholder=""
                        size="1rem"
                        aria-label="Search"
                        type="Search"
                        variant="unstyled"
                        name="busca"
                        icon={<IconSearch size="1rem" />}
                        style={{
                          marginRight: "20px",
                          background: "transparent",
                        }}
                      ></TextInput>
                    </div>
                  </section>
                </MDBCol>
                <MDBCol size="12" lg="2" xxl="1" offsetLg={0} className="">
                  <MDBBtn
                    gradient="aqua"
                    rounded
                    size="sm"
                    className="botaosearch me-0 mr-lg-auto w-auto mb-3 hover-overlay"
                    style={{ borderRadius: "100px" }}
                  >
                    <p style={{ margin: "auto" }}>Pesquisar</p>
                    <div
                      className="mask"
                      style={{
                        background:
                          "linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)",
                      }}
                    ></div>
                  </MDBBtn>
                </MDBCol>
              </form>
            </MDBRow>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}

