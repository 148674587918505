import './Slider.css'
import tecnicos from '../img/tecnicos.png'
import macbook from '../img/macbook.jpg'
import setup from "../img/setup.jpg";
import { Button } from '@mantine/core';
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

export default function App() {
  const textCustom = "Olá! Eu gostaria de monstar um PC custom!"
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className="div-carousel"
        style={{
          marginTop: "40px",
          marginBottom: "20px",
          overflow: "hidden",
        }}
      >
        <MDBCarousel showIndicators showControls className="carousel">
          <div className="carousel-inner" style={{ borderRadius: "10px" }}>
            <MDBCarouselItem
              className="w-100 d-block"
              itemId={1}
              src={macbook}
              alt="..."
            >
              <h5>QUALIDADE</h5>
              <p>Na i9, você encontra produtos de qualidade com segurança, não tem como errar!</p>
            </MDBCarouselItem>

            <MDBCarouselItem
              className="w-100 d-block "
              itemId={2}
              src={setup}
              alt="..."
            >
              <div className='lightbackground' >
              <h5>I9 GAMER</h5>
              <p>Monte o seu setup gamer dos sonhos por preços imperdíveis!</p>
              
              </div>
              <a href={`https://wa.me/553197892322?text=${textCustom}`}>
                <Button variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }} mt={10}>Quero montar um PC!</Button>
              </a>
            </MDBCarouselItem>

            <MDBCarouselItem
              className="w-100 d-block"
              itemId={3}
              src={tecnicos}
              alt="..."
            >
              <div className='lightbackground'>
              <h5>PROFISSIONALISMO</h5>
              <p >
                Não se preocupe, contamos com profissionais especializados na nossa equipe.
              </p>
              </div>
            </MDBCarouselItem>
          </div>
        </MDBCarousel>
      </div>
    </div>
  );
}
